<template>
    <v-container>
        <h1><a v-bind:href="'/'" style="color: #000000 ;text-decoration: none;"> Главная / Доставка и оплата</a></h1>
        <p>ДОСТАВКА</p>
        <p>КУРЬЕРСКАЯ ДОСТАВКА</p>
        <p>Курьерская доставка в пределах МКАД ,осуществляется по г. Москве на следующий день после подтверждения заказа оператором.</p>
        <p>Доставка в пределах МКАД - бесплатно</p>
        <p>На доставку за пределами МКАД действуют следующие тарифы:</p>
        <p>- За МКАД до 20 км -150 рублей</p>
        <p>- За МКАД от 20 до 40 км - 440 рублей</p>
        <p>- За МКАД от 40 км - 640 рублей</p>
        <p>• При отказе от заказа оплачивается стоимость доставки.</p>
        <p>• Время ожидания курьера не более 30 минут. Время отведенное для примерки товара не более 15 минут.</p>
        <p>ДОСТАВКА ПО РОССИИ осуществляется:</p>
        <p>В регионы мы отправляем заказы через СДЭК.</p>
        <p>Срок доставки примерно 3-5 дней. Если Дальний Восток, то дольше (уточняйте у менеджера)</p>
        <p>Мы работаем по предоплате в регионы. Оплата доставки - как гарантия Ваших намерений. Мы хотим быть уверены, что Вы делаете заказ осознанно и вы не передумаете пока ковер будет в пути.</p>
        <p>ОПЛАТА</p>
        <p>Способы оплаты:</p>
        <p>При заказе курьерской доставки по г.Москва и г. Санкт-Петербургу, оплата производится:</p>
        <p>- Наличными курьеру при получении заказа.</p>
        <p>Доставка по России осуществляется в любой регион.</p>
        <p>- Вы оплачиваете покупку только после получения товара на руки</p>
        <p>КАК ОФОРМИТЬ ЗАКАЗ</p>
        <p>1) Выберете интересующую Вас модель, затем нажмите кнопку "Добавить в корзину"</p>
        <p>2) Нажмите на значок "Корзина"</p>
        <p>3) На странице "Корзина" Вы увидите те товары, которые заказали. Вы можете изменить количество или удалить товар, после чего необходимо нажать кнопку "Оформить".</p>
        <p>4) Заполняете персональную информацию.</p>
        <p>5) После оформления заказа, в ближайшее время наш менеджер свяжется с Вами.</p>
        <p>ВНИМАНИЕ!</p>
        <p>Просим Вас помнить, что все технические параметры и потребительские свойства приобретаемого товара следует уточнять у наших консультантов до момента покупки товара. В обязанности работников Службы доставки не входит осуществление консультаций и комментариев относительно потребительских свойств товара. При доставке вам заказанного товара проверяйте комплектность доставленного товара, соответствие доставленного товара описанию на нашем сайте, также проверьте товар на наличие брака. При не заявлении вами при получении товара претензий по поводу брака, в дальнейшем подобные претензии не рассматриваются.</p>
    </v-container>
</template>
<script>
    
export default {
  metaInfo:{
    title: 'Доставка и оплата',
    meta: [
      {
        name: 'description',
                 content: 'Курьерская доставка в пределах МКАД ,осуществляется по г. Москве и Санкт-Петербургу на следующий день после подтверждения заказа оператором.',
      },
      {
        name: 'keywords',
        content: 'Ковры, fisolini, фисолини, компания фисолини, ковры fisolini.ru, ковры'
      }
    ]
  },
  data(){
    return {
    }
  },
}

</script>
